import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TWCard from '../card/card';
import { FaHome, FaHouseDamage } from 'react-icons/fa';
import './services.css';

const KWServices = () => {
  return (
    <div className="left-align-container">
      <Container>
        <Row>
          <Col sm={12} lg={12} className='mt-5 mt-lg-0'>
            <Row>
              <Col sm={12} lg={6}>
                <TWCard
                  icon={<FaHouseDamage size={50} color='#044dac' />}
                  heading="Consulting & Legal Services"
                  paragraph={
                    <>
                      <p>Navigating the dynamic real estate market requires expertise, and that’s where our property consultancy and legal services excel. With a seasoned team of professionals, we provide comprehensive insights, strategic advice, and tailored solutions to ensure seamless and successful property transactions.</p>
                      <p>Whether you’re buying, selling, or investing, our consultants utilize their deep market knowledge to offer a nuanced understanding of current trends, pricing dynamics, and potential opportunities. We are committed to fostering long-term relationships, prioritizing your goals, and delivering results that exceed expectations.</p>
                      <p>With a focus on integrity, transparency, and personalized service, partnering with us is an investment in your financial success and peace of mind. Let us guide you on the journey to real estate excellence.</p>
                    </>
                  }
                />
                <TWCard
                  icon={<FaHome size={50} color='#044dac' />}
                  heading="Rentals"
                  paragraph={
                    <>
                      <p>Your Ideal Home Awaits!</p>
                      <p>
                        Choose our rentals for a living experience that seamlessly combines comfort, convenience, and quality. Featuring modern amenities, meticulously maintained interiors, and prime locations, our properties are designed to exceed your expectations.
                      </p>
                      <p>
                         We prioritize tenant satisfaction through responsive maintenance services and clear, transparent communication. With a variety of thoughtfully designed rental options to suit diverse preferences and budgets, you’re sure to find the perfect space to call home.
                      </p>
                      <p>
                         Experience a lifestyle that puts your needs first and elevates your everyday living. Choose our rentals for more than just a place to reside, choose a place where you truly belong.
                      </p>
                    </>
                  }
                />
              </Col>
              <Col sm={12} lg={6} className='mt-lg-5'>
                <TWCard
                  icon={<FaHouseDamage size={50} color='#044dac' />}
                  heading="Tenants"
                  paragraph={
                    <>
                      <p>
                        Why Choose K-W Property Management Corp.?
                       </p>
                      <p>
                        At K-W Property Management Corp. tenant satisfaction is the cornerstone of our philosophy. We are dedicated to creating a positive, comfortable living experience for all our tenants through the following commitments:
                      </p>
                      <p>
                        Responsive Maintenance: Our maintenance team is committed to addressing issues promptly, ensuring properties are well-maintained and enhancing your quality of life.</p>
                      <p>
                        Transparent Communication: We keep tenants informed with regular updates, event notifications, and important property announcements.
                      </p>
                      <p>
                        Convenient Online Tools: Our user-friendly platform makes rent payments, lease renewals, and maintenance requests seamless and hassle-free.
                      </p>
                      <p>
                        Community Engagement: We foster a sense of belonging by organizing community events and cultivating a supportive, inclusive environment. 
                      </p>
                      <p>
                        When you choose K-W Property Management Corp. you’re choosing a partner that genuinely cares for your well-being and strives to exceed expectations at every turn. 
                      </p>
                    </>
                  }
                />
                <TWCard
                  icon={<FaHome size={50} color='#044dac' />}
                  heading="Property Management & Leasing Services"
                  paragraph={
                    <>
                      <p>
                        Selecting K-W Property Management Corp. for your property management and leasing needs guarantees exceptional service defined by professionalism, efficiency, and a steadfast commitment to client satisfaction. With a proven track record in the industry, we deliver tailored property management solutions designed to meet your unique requirements.
                      </p>
                      <p>
                        Our team of seasoned experts brings deep market knowledge and expertise to help maximize the value of your investment. Through a proactive approach, we promptly address issues and implement strategic leasing initiatives to minimize vacancies and ensure optimal performance.
                      </p>
                      <p>
                         Utilizing cutting-edge technology and maintaining transparent communication, we keep you informed and empowered to make the best decisions for your property. Partnering with K-W Property Management Corp. means entrusting your property to a trusted name dedicated to optimizing its potential while delivering a seamless, worry-free experience for both owners and tenants.
                      </p>
                    </>
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default KWServices;
