import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { LineWave } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import swal from 'sweetalert';
import KWAdminNavbar from '../../components/navbar/AdminNav';
import DOMPurify from 'dompurify';
import Select from 'react-select';
import { verifyToken } from '../../utils/verifyToken';
import { ToastContainer, toast } from 'react-toastify';
import axiosInstance from '../../utils/AxiosInstance';
import { fetchAgents } from '../../utils/fetchAgents';

const UpdateEvent = () => {
    const navigate = useNavigate();
    const [isSubmitting, setSubmitting] = useState(false);
    const [property, setProperty] = useState([]);
    const formRef = useRef(null);
    const location = useLocation();
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [agentData, setAgentData] = useState([])
    const [status, setStatus] = useState(location.state?.summary.match(/\[[a-z]+\]/)?.[0] || '[o]');
    const [showingData, setShowingData] = useState({
        description: location.state?.description || '',
        summary: location.state?.summary?.replace(/\[[a-zA-Z]+\]/, '')?.trim() || '',
        start: {
            dateTime: location.state?.start?.dateTime || '',
            timeZone: "America/Los_Angeles",
        },
        end: {
            dateTime: location.state?.end?.dateTime || '',
            timeZone: "America/Los_Angeles",
        },
        extendedProperties: {
            shared: {
                propertyId: location.state?.extendedProperties?.shared?.propertyId || '',
                agentName: location.state?.extendedProperties?.shared?.agentName || '',
                unitTitle: location.state?.extendedProperties?.shared?.unitTitle || '',
                propertyTitle: location.state?.extendedProperties?.shared?.propertyTitle || '',
                showing: location.state?.extendedProperties?.shared?.showing || 'open',
                colorCode:location.state?.extendedProperties?.shared?.colorCode||'',
                PId:location.state?.extendedProperties?.shared?.PId||''
            },
            private: {},
        },
        details: {
            meetingStatus: location.state?.details?.meetingStatus || '',
            summery: location.state?.details?.summery || '',
        },
        colorId: location.state?.colorId || '',
    });

    useEffect(() => {
        getAllAgents();
        verifyToken(); // verify token on page load
    }, [])
    const handleAgentSelect = (agentName) => {
        const sanitizedValue = DOMPurify.sanitize(agentName);
        setSelectedAgent(() => agentData.find(agent => agent.name === sanitizedValue));
        setShowingData(prev => ({
            ...prev,
            extendedProperties: {
                ...prev.extendedProperties,
                shared: {
                    ...prev.extendedProperties.shared,
                    agentName: sanitizedValue,
                },
            },
        }));
    };
    const getAllAgents = async () => {
        const { error, data } = await fetchAgents();
        if (!error) setAgentData(data);
    }

    useEffect(() => {
        fetchProperties();
        handleAgentSelect(location.state?.extendedProperties?.shared?.agentName)
    }, [navigate,agentData]);

    useEffect(() => {
        if (property.length > 0 && showingData.extendedProperties.shared.propertyId) {
            const defaultProperty = property.find(
                (opt) => opt.value.Unit.Id === showingData.extendedProperties.shared.propertyId
            );
            setSelectedProperty(defaultProperty);
        }
    }, [property, showingData.extendedProperties.shared.propertyId]);

    const fetchProperties = async () => {
        try {
            const { data } = await axiosInstance().get(`/v1/listing/kwpweb`);
            const options = data.map((item) => ({
                value: item, // Include full property object
                label: (
                    <div>
                        <strong>{item.Property.Name}</strong>
                        <br />
                        <span style={{ fontSize: 'smaller', color: '#888' }}>{item.Property.PropertyUnitTypeTitle}</span>
                    </div>
                )
            }));
            setProperty(options);
        } catch (err) {
            console.error(err);
        }
    };

    const handleSubmit = async () => {
        setSubmitting(true);
        if (new Date(showingData.start.dateTime) >= new Date(showingData.end.dateTime)) {
            toast.error('Start date must be before end date.');
            setSubmitting(false);
            return;
        }
        try {
            const payload = {
                description: showingData.description,
                summary: `${status} ${showingData.extendedProperties.shared.propertyTitle}`.trim(),
                start: {
                    dateTime: showingData.start.dateTime,
                    timeZone: showingData.start.timeZone,
                },
                end: {
                    dateTime: showingData.end.dateTime,
                    timeZone: showingData.end.timeZone,
                },
                extendedProperties: {
                    shared: {
                        agentName: showingData.extendedProperties.shared.agentName,
                        propertyId: showingData.extendedProperties.shared.propertyId,
                        unitTitle: showingData.extendedProperties.shared.unitTitle,
                        propertyTitle: showingData.extendedProperties.shared.propertyTitle,
                        showing: showingData.extendedProperties.shared.showing || 'open',
                        colorCode:selectedAgent?.color_code || showingData.extendedProperties.shared.colorCode,
                        PId:showingData.extendedProperties.shared.PId||location.state?.extendedProperties?.shared?.PId
                    },
                    private: {},
                },
                details: {
                    meetingStatus: showingData.details.meetingStatus,
                    summery: showingData.details.summery,
                },
                colorId: selectedAgent?.colorId || location.state?.colorId
                ,
            };

            await axiosInstance().patch(`/v1/showing/event/${location.state?.id}`, payload);

            swal("Success", "Event updated successfully", "success").then(() =>
                navigate('/admin/event-list')
            );
        } catch (err) {
            console.error(err);
            swal("Error", "Failed to update event. Please try again.", "error");
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <ToastContainer />
            <KWAdminNavbar />
            <Container className='pt-5'>
                <div className='form-content'>
                    <Col className='mx-auto' sm={12} lg={6}>
                        <Card className='pt-3 border-0 form-card mt-4'>
                            <h4 className='text-center'>Update Event</h4>
                            <Form ref={formRef}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Property</Form.Label>
                                    <Select
                                        options={property}
                                        value={selectedProperty} // Default selection
                                        onChange={(selectedOption) => {
                                            setSelectedProperty(selectedOption);
                                            setShowingData((prev) => ({
                                                ...prev,
                                                extendedProperties: {
                                                    ...prev.extendedProperties,
                                                    shared: {
                                                        ...prev.extendedProperties.shared,
                                                        propertyId: selectedOption.value.Unit.Id,
                                                        unitTitle: selectedOption.value.Property.PropertyUnitTypeTitle, // Add unitTitle
                                                        propertyTitle: selectedOption.value.Property.Name, // Add propertyTitle
                                                        showing: 'open', // Add default showing value
                                                        PId:selectedOption.value.Property.PId
                                                    },
                                                },
                                                summary: `${status} ${selectedOption.value.Property.Name}`.trim(), // Update summary with status
                                            }));
                                        }}
                                        placeholder="Select Property"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Agent Name <span className='text-danger'>*</span></Form.Label>
                                    <DropdownButton style={{ background: `${selectedAgent?.color_code}` }} className='form-control agent p-0' id="dropdown-basic-button" title={selectedAgent?.name || 'Select Agent'}>
                                        {agentData.length > 0 ? (
                                            agentData.map((A, index) => (
                                                <Dropdown.Item
                                                    key={index}
                                                    style={{ background: selectedAgent?.name === A.name ? '#64626269' : '' }}
                                                    onClick={() => handleAgentSelect(A.name)}
                                                    className='d-flex justify-content-between align-items-center'>
                                                    {A.name}
                                                    <span style={{ color: `${A.color_code}`, fontSize: '30px' }}>●</span>
                                                </Dropdown.Item>
                                            ))
                                        ) : (
                                            <Dropdown.Item disabled>No Agent available</Dropdown.Item>
                                        )}
                                    </DropdownButton>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Start date</Form.Label>
                                    <Form.Control
                                        value={moment(showingData.start.dateTime).format("YYYY-MM-DDTHH:mm")}
                                        onChange={(e) => setShowingData(prev => ({
                                            ...prev,
                                            start: {
                                                ...prev.start,
                                                dateTime: new Date(e.target.value).toISOString(),
                                            },
                                        }))}
                                        name='start'
                                        type="datetime-local" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>End date</Form.Label>
                                    <Form.Control
                                        value={moment(showingData.end.dateTime).format("YYYY-MM-DDTHH:mm")}
                                        onChange={(e) => setShowingData(prev => ({
                                            ...prev,
                                            end: {
                                                ...prev.end,
                                                dateTime: new Date(e.target.value).toISOString(),
                                            },
                                        }))}
                                        name='end'
                                        type="datetime-local" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        value={showingData.description}
                                        onChange={(e) => setShowingData(prev => ({
                                            ...prev,
                                            description: DOMPurify.sanitize(e.target.value),
                                        }))} name='description' rows={3} placeholder="Description" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select className={`text-${status === '[o]' ? 'success' : 'danger'}`} value={status} onChange={(e) => setStatus(e.target.value)} name="status">
                                        <option className='text-success' value="[o]">Open</option>
                                        <option className='text-danger' value="[c]">Closed</option>
                                    </Form.Select>
                                </Form.Group>
                                <div className='d-flex justify-content-start'>
                                    <Button onClick={handleSubmit} className='btn btn-primary mt-2' disabled={isSubmitting}>
                                        {isSubmitting ? (
                                            <LineWave
                                                height="20px"
                                                width="70px"
                                                color="#ffffff"
                                                ariaLabel="line-wave"
                                                visible={true}
                                            />
                                        ) : "Submit"}
                                    </Button>
                                    <Button onClick={() => navigate('/admin/event-list')} className='btn btn-danger ms-2 mt-2' disabled={isSubmitting}>
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                        </Card>
                    </Col>
                </div>
            </Container>
        </>
    );
};

export default UpdateEvent;
