export const team = [
    {
        id: 1,
        name: 'Trisha Montag',
        designation: 'CEO',
        description: 'Trisha founded the company in 2011 with a hands-on approach and a vision for exceptional property management. As an experienced property owner of both commercial and residential units in the tri-city area, she understands the industry inside and out. She actively monitors market trends to ensure the company stays ahead of changes in the rental landscape. Trisha is dedicated to upholding high standards across all aspects of the business and takes great pride in the incredible team she has built.',
        note: 'Outside of work, she enjoys spending time with her husband and three sons.',
        img: './team/trish.jpg'
    },
    {
        id: 2,
        name: 'Flora Mehboob',
        designation: 'Business Operations Manager',
        description: 'As our Business Operations Manager, Flora oversees all departments, providing support where needed and ensuring smooth day-to-day operations. She also plays a crucial role in onboarding new property owners and ensuring they have a seamless experience. With a diploma in Business Administration and over seven years with the company, Flora brings extensive expertise in managing operations efficiently. In addition to her role, she is also a residential real estate investor, giving her firsthand insight into property ownership and management.',
        note: 'When she’s not at work, she enjoys spending time with her husband and two children, relaxing, and traveling whenever possible.',
        img: './team/flora.jpg',
    },
    {
        id: 3,
        name: 'Noah Koop',
        designation: 'Director of Maintenance and Leasing',
        description:
            'Noah is responsible for overseeing our maintenance and leasing departments, ensuring that all properties are well-maintained and new clients are onboarded smoothly. With a background in business management and seven years of experience with the company, he has developed strong leadership and problem-solving skills that help him navigate the complexities of property management.',
        note: 'Outside of work, he enjoys fishing, skiing, and playing golf.',
        img: './team/Noah.jpg',
    },
    {
        id: 4,
        name: 'Cole Wilton',
        designation: 'Financial Controller',
        description: 'As the Financial Controller, Cole handles all financial matters, including account reconciliation, accounts receivable, and assisting property owners with payments, portals, invoices, and account statements. With a background in accounting and marketing, along with a degree in business marketing, he also has experience in handling and preparing marketing materials for new home builds.',
        note: 'When not crunching numbers, Cole enjoys playing hockey, golfing, and traveling to explore new destinations with his family.',
        img: './team/cole.jpg',
    },
    {
        id: 5,
        name: 'Debbie Montag',
        designation: 'Landlord Licensing & Office Support Coordinator',
        description: 'Debbie plays a key role in ensuring landlords meet local licensing requirements while also providing essential support to our office operations. She assists property owners in navigating licensing processes, staying compliant with regulations, and keeping everything running smoothly behind the scenes. More than just a team member, Debbie is Trisha’s mother and has been by her side since day one of this journey. With relentless dedication, she has played an integral role in the company’s growth and success, providing unwavering support every step of the way.',
        note: 'When she’s not at work, Debbie loves spending time with her five grandchildren—four boys and one girl—who keep her on her toes and bring endless joy to her life.',
        img: './team/Deb.jpg',
    },
    {
        id: 6,
        name: 'Nada Cupic',
        designation: 'Field Inspector Specialist',
        description: 'Nada brings over a decade of experience in property inspections and general contracting. With a background in property management and Landlord and Tenant Board legal services as a licensed paralegal, she ensures that all properties meet the highest standards.',
        note: 'Nada enjoys spending time outdoors, fishing, and making memories with her nephews and family.',
        img: './team/nada.jpg',
    },
    {
        id: 7,
        name: 'Skylar Escott',
        designation: 'Office Administrator',
        description: 'As the first point of contact for our office, Skylar manages daily administrative tasks, including answering phone calls, assisting contractors, and coordinating move-ins and cleanings. With a background in customer service and experience in hospitality and events, she ensures smooth interactions between tenants, landlords, and service providers.',
        note: 'When she’s not at work, Skylar enjoys socializing with friends and traveling with her husband.',
        img: './team/skylar.jpg',
    },
    {
        id: 8,
        name: 'Tavleen Kaur',
        designation: 'Legal and Collections Coordinator',
        description: 'Tavleen ensures that all legal and collections matters are handled efficiently. She follows up with tenants on utility collections, negotiates payment plans, and keeps landlords informed. She also prepares legal documents and ensures proper procedures are followed in compliance with the Landlord and Tenant Board.',
        note: 'With a background in fast-paced customer service, Tavleen has developed strong communication and dispute-resolution skills, which are essential in handling legal and collections-related matters.',
        img: './team/Tav.jpg',
    },
    {
        id: 9,
        name: 'Cassie Ly',
        designation: 'Leasing Administrator & Leasing Agent',
        description: 'Cassie specializes in processing applications and guiding future tenants through the move-in process, ensuring a seamless transition into their new homes. As the dedicated leasing agent for La Loma Residences, she provides hands-on assistance from application to lease signing. With over four years of experience in real estate, Cassie brings strong customer service, communication, and problem-solving skills to the team.',
        note: 'In her free time, she enjoys exploring new cuisines and spending time with family and friends.',
        img: './team/Cassie.jpeg',
    },
    {
        id: 10,
        name: 'Kristi Young',
        designation: 'Leasing Administrator',
        description: 'Kristi plays a vital role in ensuring a seamless leasing process for both tenants and property owners. From drafting and reviewing leases to coordinating smooth condo move-ins, she helps tenants transition effortlessly into their new homes. She also assists with advertising, marketing, and managing move-outs, ensuring that every detail is handled with care.',
        note: 'Outside of work, Kristi loves spending time outdoors with her husband, their two beautiful daughters, and their dog, Oakley.',
        img: './team/kristi.jpg',
    },
    {
        id: 11,
        name: 'Shea Hinds',
        designation: 'Leasing Specialist',
        description: 'Shea plays a key role in marketing properties, finding qualified tenants, and managing the rental process from start to finish. He also handles administrative duties, ensuring property owners and tenants receive timely updates throughout the leasing process.With seven years of sales experience, a college diploma in Business Marketing, and firsthand knowledge as a real estate investor with two rental properties, Shea brings a strong understanding of the rental market and tenant relations. His background in sales and marketing allows him to effectively position properties for maximum exposure.',
        note: 'Outside of work, Shea enjoys traveling and spending time with his fiancée and their dog. He is also an avid sports fan, with a particular love for hockey and golf.',
        img: './team/Shea.jpg',
    },
    {
        id: 12,
        name: 'Andrew Scott',
        designation: 'Leasing Agent',
        description: 'Andrew specializes in assisting clients with property showings, helping them find the perfect home, and navigating tenant issues and maintenance concerns. His keen understanding of client needs and dedication to seamless service make him a valuable part of the leasing team. Since joining the company two years ago, Andrew has leveraged his diverse background in sales across technology, consumer needs, and hospitality to enhance his approach to real estate. A recent graduate of the University of Waterloo with a BA in Psychology and a Minor in Human Resource Management, he combines academic insight with hands-on experience in tenant relations and leasing.',
        note: 'Originally from St. Catharines, Andrew enjoys an active lifestyle, including golfing, fitness, and playing pick-up basketball with friends.',
        img: './team/Andrew.jpeg',
    },
    {
        id: 13,
        name: 'Michael Pfeifer',
        designation: 'Maintenance Coordinator',
        description: 'Michael plays a key role in ensuring the properties under our management are well-maintained. He works closely with tenants, landlords, and contractors to address maintenance concerns in a timely manner, ensuring smooth property operations. With a background in commercial exterior maintenance, including storefronts and landscaping, Michael brings valuable hands-on experience to his role.',
        note: 'Outside of work, he enjoys strength training at the gym and spending time with his girlfriend and their three cats.',
        img: './team/Michael.jpg',
    },
    {
        id: 14,
        name: 'Kato',
        designation: 'Security',
        description: 'Kato is our trusted security specialist, trained at Baden K-9 in Niagara Falls. With over 3,600 hours of tolerance and bite training, she plays a vital role in ensuring the safety and security of our staff.',
        note: 'When she\'s off duty, Kato loves hanging out at home with her brother, a Great Dane, and spends her time playing catch and hiding bones.',
        img: './team/Kato.jpg',
    },
    {
        id: 15,
        name: 'John Rosh',
        designation: 'Manager of Off-Site Operations',
        description: 'John plays a critical role in managing tenant relations and rent collection for our off-site operations. With six years of property management experience, he ensures tenants fulfill their payment obligations, handles legal disputes when necessary, and keeps property owners informed on financial matters and key updates. With expertise in handling complex rental situations requiring careful negotiation and resolution, John fosters strong relationships with both tenants and landlords, ensuring a seamless rental experience. His exceptional organizational skills, calm demeanor, and proactive approach make him an invaluable part of the team.',
        note: 'Outside of work, John enjoys exploring the outdoors, traveling, and spending quality time with his family.',
        img: './team/default.jpg',
    },
    {
        id: 16,
        name: 'Ben Reyes',
        designation: 'Resident Accounts & Support Coordinator',
        description: 'Ben oversees all rent-related matters, ensuring timely collections, maintaining landlord communication, and handling legal cases with the Landlord and Tenant Board (LTB). With over a decade of experience in the industry, he brings strong leadership and strategic insight to the team. A seasoned real estate investor with a diverse property portfolio, Ben’s hands-on approach has given him a deep understanding of both real estate investment and tenant management. His degree in Business Administration further strengthens his ability to navigate financial and operational aspects of property management.',
        note: 'Beyond work, Ben has a passion for music and enjoys spending quality time with his family.',
        img: './team/default.jpg',
    },
    {
        id: 17,
        name: 'Justin Cruz',
        designation: 'Leasing Administrator',
        description: 'Justin plays a key role in the leasing process, ensuring properties receive maximum exposure across various platforms, managing tenant applications, and collaborating closely with property owners for approvals. His attention to detail and commitment to streamlining the leasing experience make him an essential part of the team. With two years of experience in leasing and tenant relations, Justin continuously expands his expertise by staying updated on market trends and best practices in property leasing.',
        note: 'Outside of work, he enjoys staying active, exploring new places, and spending time with family and friends.',
        img: './team/default.jpg',
    },
    {
        id: 18,
        name: 'David Valdez',
        designation: 'Maintenance Coordinator',
        description: 'David is responsible for coordinating maintenance requests, ensuring issues are resolved efficiently for both tenants and property owners. With two years at K-W Property Management, he has been an integral part of keeping properties well-maintained and operations running smoothly. Holding an MBA in Banking & Finance, David brings strong analytical and problem-solving skills to the team. His ability to manage complex maintenance projects and coordinate with contractors makes him a key asset to the company.',
        note: 'Outside of work, David values quality time with his family and enjoys maintaining a balance between his professional and personal life.',
        img: './team/default.jpg',
    },
    {
        id: 19,
        name: 'Carlos Rosh',
        designation: 'Leasing Administrator',
        description: 'Carlos specializes in leasing and tenant relations, ensuring a smooth rental experience from lease drafting to finalizing agreements. Transitioning from a career in web development, he brings a fresh perspective and technical expertise to the leasing process. With two years of experience in the real estate industry, Carlos is continuously expanding his knowledge through further studies to enhance his expertise in property management. His attention to detail, problem-solving skills, and commitment to excellence contribute to the company’s continued success.',
        note: 'Outside of work, he enjoys spending time with his family, playing sports, and exploring video games.',
        img: './team/default.jpg',
    },
    {
        id: 20,
        name: 'Jake Santos',
        designation: 'Condo Property Manager',
        description: 'Jake specializes in condominium property management, bringing five years of real estate experience and strong relationships with major condo corporations. His deep understanding of the local market and condominium regulations allows him to provide strategic solutions for property owners. Jake stays up to date on market trends, offering informed advice to clients and colleagues to ensure top-tier service. His expertise in navigating condo management complexities makes him an invaluable part of the team.',
        note: 'In his free time, Jake enjoys traveling, watching movies, and spending quality time with his family.',
        img: './team/default.jpg',
    },
];
