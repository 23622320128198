import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import './propertycard.css';
import img from '../../assets/no-image.png'
// import { LineWave } from 'react-loader-spinner';

import { useNavigate } from "react-router-dom";
import { FaBath, FaBed } from 'react-icons/fa';
import { LiaVectorSquareSolid } from 'react-icons/lia';


const KWPropertyCard = ({ currentProperty }) => {
  const [isHovered, setIsHovered] = useState(false);
  const cardHeight = '450px';
  const navigate = useNavigate()
  // console.log(currentProperty);
  const formatNumber = (number) => {
    return number.toLocaleString('en-US');
};
  return (
    <>
      <Card
        style={{ cursor: 'pointer', width: '100%', minHeight: cardHeight }}
        className={`mb-3 shadow ${isHovered ? 'animate__animated animate__pulse' : ''}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => navigate(`/listing/${currentProperty.Unit.Id}`)}
      >
        <Card.Img style={{ height: '240px', objectFit: 'cover' }}
          // onClick={() => navigate(`/listing/${currentProperty.Unit.Id}`)}
          // src={currentProperty.Property.Files[0] ? currentProperty.Property.Files[0].Url : img} //odl object 
          src={currentProperty.Property.Files ? currentProperty.Property.Files : img} 
          variant='top' 
          alt={`${currentProperty?.Property?.PropertyUnitTypeTitle} for Rent in ${currentProperty?.Property?.Address?.City.trim()}, ${currentProperty?.Property?.Address?.State.trim()} - ${currentProperty?.Unit?.UnitBedrooms} Bedroom, ${currentProperty.Unit.UnitBathrooms} Bathroom`}
          /> 
        <Card.Body>
        <Card.Title style={{ textAlign: 'left' }}>
            <h3
              // style={{ cursor: 'pointer' }}
              // onClick={() => navigate(`/listing/${currentProperty.Unit.Id}`)}
              className="text-primary">{currentProperty.Property.Name}</h3>
              <h4 className='h4'>{currentProperty.Property.PropertyUnitTypeTitle}</h4>
          </Card.Title>
          <Card.Text>
            {/* {!mapCard ? currentProperty.Property.StructureDescription : ''} */}
            <h6 className="mt-2">
              <span className="text-primary">${formatNumber(currentProperty.Rent)}</span> /month
            </h6>
          </Card.Text>
          <Card.Text className='text-capitalize'>
            <h5 className='fs-6 fw-normal'>Address: {currentProperty.Property.Address.AddressLine1.toLowerCase()}.</h5>
            <div className='d-flex mt-2 pt-2 justify-content-between'>
              <div>
                <FaBed title='Bedrooms' size={20} className="icon-primary" />
                <span className='text-capitalize'>{" " + currentProperty?.Unit?.UnitBedrooms || 'N/A'}</span>
              </div>
              <div className='d-flex gap-2 align-items-center'>
                <FaBath title='Bathrooms' size={20} className="icon-primary" />
                <span>{" " + currentProperty?.Unit?.UnitBathrooms || 'N/A'}</span>
              </div>
              <div>
                <LiaVectorSquareSolid title='Sq Ft' size={20} className="icon-primary" />
                <span>{" " + (currentProperty?.Unit?.UnitSize !== 0 ? currentProperty?.Unit?.UnitSize : 'N/A')}</span>
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default KWPropertyCard;
