import axiosInstance from "./AxiosInstance";


export const fetchAgents = async () => {
    try {
        const response = await axiosInstance().get(`/v1/showing/agents`);
        return { error: false, data: response.data.data }
    } catch (error) {
        console.log(error)
        return { error: true, er_ror: error.response }
    }
}