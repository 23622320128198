import React from 'react';
import { Form } from 'react-bootstrap';

const TermsAndConditionsSection = ({ isTermsAndConditionsChecked, setTermsAndConditionsChecked }) => (
    <div className="border rounded p-3 mt-3">
        <div className='fs-4 justify-content-between d-flex'>
            <div><b className='service-form-para2-H'>Terms and Conditions</b></div>
        </div>
        <div className="service-form-para2">
            <div className="service-form-para2-text">
                Tenants please be advised you will be charged for a service call if the following work order is a result of tenant neglect or damage. Also including below but not limited to:
            </div>
            <ul>
                <li><strong>CLOGGED SINKS</strong> - This service call is a charge to the tenants if the pipes are full of debris. The tenant will not be charged if the pipe is structurally compromised.</li>
                <li><strong>NO HYDRO</strong> - The tenant will be charged for this service call if the breakers are off or fuses are burnt out. The tenant will also be charged if the receptacle reset button needs to be pressed to reset. Please ensure you have checked breakers, fuses, and reset buttons prior to our company sending out an electrician to avoid costs.</li>
                <li><strong>FURNACE NOT WORKING</strong> - Ensure the outside vent (exhaust vent) is not clogged by debris, ice, or snow. If an HVAC technician determines a blocked vent as the cause, the tenant will be responsible for the service call. The tenant is also responsible for service if thermostat batteries are low or a dirty furnace prevented functionality. (Furnace filters should be changed every 3 months). <strong>Make sure Furnace and/or AC switch is turned on, or the tenant will be held responsible for the service call.</strong></li>
                <li><strong>LIGHT BULBS</strong> - The tenant is responsible for changing all burnt-out light bulbs.</li>
                <li><strong>NO ACCESS GRANTED</strong> - If you request to be home during a service call, ensure you are present. If you fail to grant access after requesting the service, you will be charged.</li>
                <li><strong>WATER SOFTENER</strong> - If your work order concerns a continuously running water softener, place it on bypass by turning the valve manually or selecting bypass on the program screen. If you cannot determine bypass, please call our office immediately.</li>
            </ul>
        </div>
        <div className="mt-2 ps-2">
            <div className='mb-3'>
                <div className='service-form-label2'>I accept these terms and conditions:</div>
                <Form>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={isTermsAndConditionsChecked}
                        onChange={() => setTermsAndConditionsChecked(!isTermsAndConditionsChecked)}
                    />
                </Form>
            </div>
        </div>
    </div>
);

export default TermsAndConditionsSection;
