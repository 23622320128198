import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { sameAsLinks } from "../../constants/schemaConstants"; // Import common sameAs array

const Faq = () => {
    return (
        <>
            <Helmet>
                <title>Frequently Asked Questions | KW Property Management</title>
                <meta
                    name="description"
                    content="Find answers to common questions about renting, property management, tenant services & landlord support in Kitchener, Waterloo & Cambridge. Get help now!"
                />
                <meta
                    name="keywords"
                    content="KW Property FAQ, Rental FAQs, Property management questions, Tenant support Kitchener, Landlord services Waterloo, Renting help Cambridge, Lease agreement FAQs, Maintenance request guide, Security deposit rules, Rental policies Ontario"
                />
                <link rel="canonical" href="https://www.kwproperty.com/FAQ" />
                {/* Defines the schema.org context for structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                            "@type": "Question",
                            "name": "Where do you advertise?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "We advertise on all social media outlets, our company website, Kijiji and various other websites. We also work closely with licensed realtors and relocation companies who will refer us many Tenants."
                            }
                        },{
                            "@type": "Question",
                            "name": "How do you screen Tenants?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "We preform credit checks, employment checks, set an appropriate debt ratio, we call for landlord references including, but not limited to, the current and previous landlord. We may also conduct an interview process."
                            }
                        },{
                            "@type": "Question",
                            "name": "What do you do if a tenant does not pay rent?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "If a Tenant does not pay rent by the third of the month we will issue an N4. This is a notice to terminate tenancy for non payment of rent, otherwise known as an eviction notice. Typically the Tenant will pay before the time frame laid out on the N4 expires, however if the Tenant does not pay we will file with the Landlord and Tenant Board and seek an eviction. We will have to hire a Paralegal to handle the file at the Tribunal, however we can assure our Landlords we get discount rates. We will assist the Paralegal through this process and may attend the hearing as well."
                            }
                        },{
                            "@type": "Question",
                            "name": "Do you notify the Landlord of non payment of rent?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "If in 10 days from the first of the Tenant has not paid the rent, we will contact the Landlord to make them aware of the situation. It can take up to 10 days for our company to reach the Tenant by means of telephone, email or a personal visit to see what the issue is. The Landlord is more than welcome to contact us earlier if they so choose. We are always available to take our clients calls or emails, however please allow for us to get through the first couple days of the month."
                            }
                        },{
                            "@type": "Question",
                            "name": "How often do you try to contact a Tenant who has not paid rent?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "We take non payment of rent very seriously and take advantage of every avenue possible to mitigate your losses. We will continue to reach out to the Tenant several times a week and even make personal trips to their home to make arrangements for payment. We will also call their place of employment and other contacts listed on their application if we cannot get a hold of them."
                            }
                        },{
                            "@type": "Question",
                            "name": "How often do you inspect a unit?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "We inspect investment properties every four months. Three inspections will consist of touring the unit and property. One inspection a year will be a Safety Inspection. This will be conducted by a third party contractor. At this inspection smoke detectors, carbon monoxide detectors, stairs, railings, visual of the furnace, ground level look at the roof and eaves and an examination of the basement along with every room of the house will be inspected."
                            }
                        },{
                            "@type": "Question",
                            "name": "Do you raise the rent yearly?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Every year three months or more before the Tenants anniversary of beginning of the tenancy we will contact the Landlord to see if they want us to raise the rent. We will leave this in the Landlords hands to make this decision and will offer our advice if asked. The rent can only be raised by the guideline set out by the Residential Tenancies Act. The Landlord has the option to raise the rent to the guideline or less. There are some exceptions in the Act that allow for higher than guideline increase, we will discuss these options with our Landlords."
                            }
                        },{
                            "@type": "Question",
                            "name": "What if a Tenant continuously pays rent late?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "If a Tenant continuously pays the rent late we will issue an N8 Form. This is a Persistently Late Application to the Landlord and Tenant Board. Essentially this is an eviction notice for paying rent after the 1st of the month continuously, in other words late rent. Although we cannot guarantee any win at the Landlord and Tenant Tribunal we do take advantage of every opportunity the Act allows a Landlord to use in order to run their business efficiently."
                            }
                        },{
                            "@type": "Question",
                            "name": "What do I need to know about my property insurance policy?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "It is important to review your home insurance policy and to make the company aware that the unit is a rental property. They often have stipulations that could mean the difference between coverage and no coverage. If the unit shall fall vacant, the Landlords insurance company needs to be notified by the Landlord themselves."
                            }
                        },{
                            "@type": "Question",
                            "name": "How do you handle work orders?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "We ask that the Tenants submit a work order in writing. We will then follow up with it in 24 hours, unless it is an emergency in which case it will be handled immediately. Our general contractors will assess the situation and report back to our company. We will seek out quotes for large expenditures and ensure you are getting the best possible pricing. We will be in communication with the Landlord during the process. We pride ourselves on excellent pricing as we work hard to maintain good relationships with contractors to get bulk pricing."
                            }
                        },{
                            "@type": "Question",
                            "name": "Do you have an after hours call in line?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, we are on call 24 hours a day 7 days a week, 365 days a year for your tenants. In the event they have no heat, water leak, etc... we are available and ready to help."
                            }
                        },{
                            "@type": "Question",
                            "name": "Do you have a contract for your landlords?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, we ask that all Landlords who wish to use our services sign a standard Property Management contract. The contract is not for any set time and can be cancelled with thirty days written notice."
                            }
                        }],
                        "sameAs": sameAsLinks
                    })}
                </script>
            </Helmet>
            <KWNavbar />
            <Container className="mt-5 pt-5 content-container">
                <Row>
                    <Col className="content-section">
                        <h1 className='landlords-cHeader'>FREQUENTLY ASKED QUESTIONS</h1>
                        <p>
                            The frequently asked questions below contain information pertinent to landlords with respect to K-W Property Management Corp., policies and practices. If you have any additional questions, don't hesitate to <Link to={'/contactus'}>contactus</Link>.
                        </p>

                        <div className='p-3'>
                            <h4>Where do you advertise?</h4>
                            <p>
                                We advertise on all social media outlets, our company website, Kijiji and various other websites. We also work closely with licensed realtors and relocation companies who will refer us many Tenants.
                            </p>
                        </div>

                        <div className='p-3'>
                            <h4>How do you screen Tenants?</h4>
                            <p>
                                We preform credit checks, employment checks, set an appropriate debt ratio, we call for landlord references including, but not limited to, the current and previous landlord. We may also conduct an interview process
                            </p>
                        </div>


                        <div className='p-3'>
                            <h4>What do you do if a tenant does not pay rent?</h4>
                            <p>
                                If a Tenant does not pay rent by the third of the month we will issue an N4. This is a notice to terminate tenancy for non payment of rent, otherwise known as an eviction notice. Typically the Tenant will pay before the time frame laid out on the N4 expires, however if the Tenant does not pay we will file with the Landlord and Tenant Board and seek an eviction. We will have to hire a Paralegal to handle the file at the Tribunal, however we can assure our Landlords we get discount rates. We will assist the Paralegal through this process and may attend the hearing as well.
                            </p>
                        </div>


                        <div className='p-3'>
                            <h4>Do you notify the Landlord of non payment of rent?</h4>
                            <p>
                                If in 10 days from the first of the Tenant has not paid the rent, we will contact the Landlord to make them aware of the situation. It can take up to 10 days for our company to reach the Tenant by means of telephone, email or a personal visit to see what the issue is. The Landlord is more than welcome to contact us earlier if they so choose. We are always available to take our clients calls or emails, however please allow for us to get through the first couple days of the month.
                            </p>
                        </div>

                        <div className='p-3'>
                            <h4>How often do you try to contact a Tenant who has not paid rent?</h4>
                            <p>
                                We take non payment of rent very seriously and take advantage of every avenue possible to mitigate your losses. We will continue to reach out to the Tenant several times a week and even make personal trips to their home to make arrangements for payment. We will also call their place of employment and other contacts listed on their application if we cannot get a hold of them.
                            </p>
                        </div>

                        <div className='p-3'>
                            <h4>How often do you inspect a unit?</h4>
                            <p>
                                We inspect investment properties every four months. Three inspections will consist of touring the unit and property. One inspection a year will be a Safety Inspection. This will be conducted by a third party contractor. At this inspection smoke detectors, carbon monoxide detectors, stairs, railings, visual of the furnace, ground level look at the roof and eaves and an examination of the basement along with every room of the house will be inspected.
                            </p>
                        </div>

                        <div className='p-3'>
                            <h4>Do you raise the rent yearly?</h4>
                            <p>
                                Every year three months or more before the Tenants anniversary of beginning of the tenancy we will contact the Landlord to see if they want us to raise the rent. We will leave this in the Landlords hands to make this decision and will offer our advice if asked. The rent can only be raised by the guideline set out by the Residential Tenancies Act. The Landlord has the option to raise the rent to the guideline or less. There are some exceptions in the Act that allow for higher than guideline increase, we will discuss these options with our Landlords.
                            </p>
                        </div>

                        <div className='p-3'>
                            <h4>What if a Tenant continuously pays rent late?</h4>
                            <p>
                                If a Tenant continuously pays the rent late we will issue an N8 Form. This is a Persistently Late Application to the Landlord and Tenant Board. Essentially this is an eviction notice for paying rent after the 1st of the month continuously, in other words late rent. Although we cannot guarantee any win at the Landlord and Tenant Tribunal we do take advantage of every opportunity the Act allows a Landlord to use in order to run their business efficiently.
                            </p>
                        </div>

                        <div className='p-3'>
                            <h4>What do I need to know about my property insurance policy?</h4>
                            <p>
                                It is important to review your home insurance policy and to make the company aware that the unit is a rental property. They often have stipulations that could mean the difference between coverage and no coverage. If the unit shall fall vacant, the Landlords insurance company needs to be notified by the Landlord themselves.
                            </p>
                        </div>

                        <div className='p-3'>
                            <h4>How do you handle work orders?</h4>
                            <p>
                                We ask that the Tenants submit a work order in writing. We will then follow up with it in 24 hours, unless it is an emergency in which case it will be handled immediately. Our general contractors will assess the situation and report back to our company. We will seek out quotes for large expenditures and ensure you are getting the best possible pricing. We will be in communication with the Landlord during the process. We pride ourselves on excellent pricing as we work hard to maintain good relationships with contractors to get bulk pricing.
                            </p>
                        </div>

                        <div className='p-3'>
                            <h4>Do you have an after hours call in line?</h4>
                            <p>
                                Yes, we are on call 24 hours a day 7 days a week, 365 days a year for your tenants. In the event they have no heat, water leak, etc... we are available and ready to help.
                            </p>
                        </div>

                        <div className='p-3'>
                            <h4>Do you have a contract for your landlords?</h4>
                            <p>
                                Yes, we ask that all Landlords who wish to use our services sign a standard Property Management contract. The contract is not for any set time and can be cancelled with thirty days written notice.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='p-3'>
                            <Link className='m-2 fs-5 text-decoration-none' to={'/contactus'}>Contact us</Link>
                            <Link className='m-2 fs-5 text-decoration-none' to={'/browse'}>Start search</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
            <KWFooter />
        </>
    )
}

export default Faq
