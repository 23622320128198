import React, { useEffect, useState } from 'react';
import AttendeesTable from '../../components/appoinment/Index';
import { Button, Card, Container } from 'react-bootstrap';
import KWAdminNavbar from '../../components/navbar/AdminNav';
import { Link, useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import './allShowings.css';
import axiosInstance from '../../utils/AxiosInstance';
import { verifyToken } from '../../utils/verifyToken';
import { fetchAgents } from '../../utils/fetchAgents';

const AllShowings = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [itemData, setItemData] = useState();
    const navigate = useNavigate();
    const [agentsData, setAgentData] = useState([])

    const fetchData = async () => {
        axiosInstance().get(`/v1/showing/appointments`)
            .then((res) => res.data.groupedAppointments)
            .then((data) => {
                const showings = [];
                for (let key in data) showings.push(...data[key]);
                setItemData(showings);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
        const { error, data } = await fetchAgents();
        if (!error) setAgentData(data);
    };

    useEffect(() => {
        fetchData();
        verifyToken();
    }, [navigate]);

    const columns = [
        {
            name: "Agent",
            selector: row => {
                const color = agentsData?.filter(a => a.name === row?.appointments?.agentName)[0] || []
                return (
                    <div style={{ borderColor: color?.color_code }} className='underlineAgent'>
                        {row?.appointments?.agentName}
                    </div>
                )
            }

            ,
            sortable: true
        },
        {
            name: "Location",
            selector: row => (
                <>
                    <Link to={`/listing/${row?.appointments?.propertyId}`} className='text-decoration-none'>
                        <h6>{row?.appointments?.propertyTitle}</h6>
                    </Link>
                    <small className='text-start textStart'>{row?.appointments?.unitTitle}</small>

                </>
            ),
            sortable: true,
            wrap: true
        },
        {
            name: "Date",
            selector: row => <Moment format="YYYY-MM-DD HH:mm:ssa">{row?.start?.dateTime}</Moment>,
            sortable: true
        },
        {
            name: "Confirmed",
            selector: row => (
                <Link to={'/admin/attendees?id=' + row.id}>
                    {row?.appointments?.numOfAppointment||0} (View)
                </Link>
            ),
            sortable: true
        }
    ];

    return (
        <div>
            <KWAdminNavbar />
            <br />
            <br />
            <Container className=''>
                <div className='back-button'>
                    <Button
                        onClick={() => window.history.back()}
                        className='btn btn-secondary'>Back</Button>
                </div>
                <Card className='showings-card'>
                    <Card.Header className='showings-card-header'>
                        <h5>All Upcoming Showings</h5>
                    </Card.Header>
                </Card>
                <div className='table-container'>
                    <AttendeesTable columns={columns} isLoading={isLoading} data={itemData || []} />
                </div>
            </Container>
        </div>
    );
};

export default AllShowings;
