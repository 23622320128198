import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import KWAdminNavbar from '../../components/navbar/AdminNav';
import { LineWave } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DOMPurify from 'dompurify';
import AsyncSelect from 'react-select/async';
import { verifyToken } from '../../utils/verifyToken';
import axiosInstance from '../../utils/AxiosInstance';
import { fetchAgents } from '../../utils/fetchAgents';

const initialShowingData = {
  description: '',
  summary: '',
  start: {
    dateTime: '',
    timeZone: "America/Los_Angeles"
  },
  end: {
    dateTime: '',
    timeZone: "America/Los_Angeles"
  },
  extendedProperties: {
    shared: {
      propertyId: '',
      agentName: ''
    },
    private: {}
  }
};

const AddEvent = () => {
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const [property, setProperty] = useState([]);
  const [showingData, setShowingData] = useState(initialShowingData);
  const formRef = useRef(null);
  const selectRef = useRef(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [allAgents, setAllAgents] = useState([])

  useEffect(() => {
    verifyToken(); // verify token on page load
  }, []);
  const getAgents = async () => {
    const { error, data } = await fetchAgents();
    if (!error) setAllAgents(data);
  }
  useEffect(() => {
    fetchProperties();
    getAgents();
  }, [navigate]);

  const fetchProperties = async () => {
    try {
      const { data } = await axiosInstance().get(`/v1/listing/kwpweb`);
      const options = data.map((item) => ({
        value: item, // Include the full object
        label: (
          <div>
            <strong>{item.Property.Name}</strong>
            <br />
            <span style={{ fontSize: 'smaller', color: '#888' }}>{item.Property.PropertyUnitTypeTitle}</span>
          </div>
        )
      }));
      setProperty(options);
    } catch (err) {
      console.error(err);
      toast.error('Failed to fetch properties. Please try again later.');
    }
  };

  const loadOptions = (inputValue, callback) => {
    const filteredOptions = property.filter(item =>
      item.value.Property.Name.toLowerCase().includes(inputValue.toLowerCase())
    );
    callback(filteredOptions);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setShowingData((prev) => {
      if (name === "property") {
        return {
          ...prev,
          extendedProperties: {
            ...prev.extendedProperties,
            shared: {
              ...prev.extendedProperties.shared,
              propertyId: value,
            },
          },
          summary: `[o] ${event.target.selectedOptions[0].innerText}`,
        };
      }

      if (name === "start") {
        // Add 30 minutes to the start time (as string manipulation)
        const [date, time] = value.split("T");
        const [hours, minutes] = time.split(":").map(Number);

        let newMinutes = minutes + 30;
        let newHours = hours;

        if (newMinutes >= 60) {
          newMinutes -= 60;
          newHours += 1;
        }

        const endTime = `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(2, "0")}`;
        const endDateTime = `${date}T${endTime}`;

        return {
          ...prev,
          start: {
            ...prev.start,
            dateTime: value,
          },
          end: {
            ...prev.end,
            dateTime: endDateTime, // Directly set the end date/time
          },
        };
      }

      if (name === "end") {
        return {
          ...prev,
          end: {
            ...prev.end,
            dateTime: value,
          },
        };
      }

      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleAgent = (value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    setShowingData(prev => {
      return {
        ...prev,
        extendedProperties: {
          ...prev.extendedProperties,
          shared: {
            ...prev.extendedProperties.shared,
            agentName: sanitizedValue,
          }
        }
      };
    });
    setSelectedAgent(() => allAgents.filter(a => a.name === value)[0]);
  };

  const handleAddShowing = async () => {
    const { propertyId, agentName } = showingData.extendedProperties.shared;
    if (!propertyId || !agentName || !showingData.start.dateTime || !showingData.end.dateTime) {
      toast.error('Please fill in all required fields.');
      return;
    }

    if (new Date(showingData.start.dateTime) >= new Date(showingData.end.dateTime)) {
      toast.error('Start date must be before end date.');
      return;
    }

    setSubmitting(true);
    try {
      const payload = {
        ...showingData,
        start: {
          ...showingData.start,
          dateTime: new Date(showingData.start.dateTime).toISOString(), // Convert to ISO format
        },
        end: {
          ...showingData.end,
          dateTime: new Date(showingData.end.dateTime).toISOString(), // Convert to ISO format
        },
        extendedProperties:{
          ...showingData.extendedProperties,
          shared:{
            ...showingData.extendedProperties.shared,
            colorCode:selectedAgent.color_code
          }
        }
      };

      const res = await axiosInstance().post(`/v1/showing/event`, {
        ...payload,
        description: `${payload.description}`,
        colorId: `${selectedAgent?.colorId}`,
      });

      if (res.status === 200) {
        formRef.current.reset();
        selectRef.current.clearValue();
        setShowingData(initialShowingData);
        setSelectedAgent(null);
        toast.success('Event added successfully!');
      }
    } catch (err) {
      console.error(err);
      toast.error('Error adding event. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (selectedProperty) {
      setShowingData((prev) => {
        return {
          ...prev,
          extendedProperties: {
            ...prev.extendedProperties,
            shared: {
              ...prev.extendedProperties.shared,
              propertyId: selectedProperty.value.Unit.Id,
              unitTitle: selectedProperty.value.Property.PropertyUnitTypeTitle,
              propertyTitle: selectedProperty.value.Property.Name,
              showing: "open",
              appointment:"[]"
            },
          },
          summary: `[o] ${selectedProperty.value.Property.Name}`,
        };
      });
    }
  }, [selectedProperty]);

  return (
    <>
      <KWAdminNavbar />
      <ToastContainer />
      <Container className="pt-5">
        <div className="form-content">
          <Col className="mx-auto" sm={12} lg={6}>
            <Card className="pt-3 border-0 form-card mt-4">
              <h4 className="text-center">Add Showing</h4>
              <Form ref={formRef}>
                <Form.Group className="mb-3">
                  <Form.Label>Property <span className='text-danger'>*</span></Form.Label>
                  <AsyncSelect
                    cacheOptions
                    ref={selectRef}
                    loadOptions={loadOptions}
                    defaultOptions={property} // Show all options initially
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setSelectedProperty(selectedOption);
                        setShowingData((prev) => ({
                          ...prev,
                          extendedProperties: {
                            ...prev.extendedProperties,
                            shared: {
                              ...prev.extendedProperties.shared,
                              propertyId: selectedOption.value.Unit.Id,
                              PId:selectedOption.value.Property.PId
                            },
                          },
                          summary: `[o] ${selectedOption.value.Property.Name}`
                        }));
                      }
                    }}
                    placeholder="Select Property"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Agent Name <span className='text-danger'>*</span></Form.Label>
                  <DropdownButton
                    className='form-control agent p-0'
                    id="dropdown-basic-button"
                    title={selectedAgent?.name || 'Select Agent'}
                    style={{ backgroundColor: selectedAgent?.color_code || 'white' }}
                  >
                    {allAgents.length > 0 ? (
                      allAgents.map((A, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => handleAgent(A.name)}
                          className='d-flex justify-content-between align-items-center'
                        >
                          {A.name}
                          <span style={{ color: `${A.color_code}`, fontSize: '30px' }}>●</span>
                        </Dropdown.Item>
                      ))
                    ) : (
                      <Dropdown.Item disabled>No Agent available</Dropdown.Item>
                    )}
                  </DropdownButton>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Start Date <span className='text-danger'>*</span></Form.Label>
                  <Form.Control onChange={handleChange} name='start' type="datetime-local" required />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>End Date <span className='text-danger'>*</span></Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    name="end"
                    type="datetime-local"
                    value={showingData.end.dateTime || ''}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Description (Optional)</Form.Label>
                  <Form.Control
                    as="textarea"
                    onChange={handleChange}
                    name='description'
                    rows={3}
                    placeholder="Description"
                    required
                  />
                </Form.Group>

                <div className='d-flex justify-content-start'>
                  <Button
                    onClick={handleAddShowing}
                    className='btn btn-primary mt-2'
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <LineWave
                        height="20px"
                        width="70px"
                        color="#ffffff"
                        ariaLabel="line-wave"
                        visible={true}
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                  <Button className='btn btn-danger mt-2 ms-2' onClick={() => navigate('/admin/event-list')}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
        </div>
      </Container>
    </>
  );
};

export default AddEvent;