import React from 'react';
import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import './thankYou.css'
import { Helmet } from 'react-helmet';

const ThankYou = () => {
    return (
        <>
            <Helmet>
                <title>Thank You | KW Property Management</title>
                <meta
                    name="description"
                    content="Thank you for reaching out to KW Property Management! Our team will respond soon. Explore our property management services, rental solutions & resources."
                />
                <meta
                    name="keywords"
                    content="Thank you page KW Property Management, Contact confirmation KW Property, Rental service inquiry, Property management assistance, Ontario real estate services, Kitchener property support, Waterloo rental management, Cambridge tenant solutions, Real estate customer service, KW Property contact response"
                />
                <link rel="canonical" href="https://www.kwproperty.com/thankyou" />
            </Helmet>
            <KWNavbar />
            <div className="thankyou_container">
                <div className="fs-2 thankyou_heading">
                    THANK YOU <br />
                    FOR CONTACTING US!
                </div>
                <div className="mt-3 recive_text">
                    We have received your information. We will review shortly and respond if necessary. Thank you for visiting and return often to see all our new properties!
                </div>
                <div className="thankyou_phoneE">
                    <div>Phone: 519-954-8082</div>
                    <div>Email: <a href="mailto:kwp@kwproperty.com">kwp@kwproperty.com</a></div>
                </div>
                <div className="thankyou_bgBox">
                    If this is an after hours emergency such as flood, fire or heating problems, please call our office at 519-954-8082 to be connected to the emergency after hours line.
                </div>
            </div>
            <div className="">
                <KWFooter />
            </div>
        </>
    );
};

export default ThankYou;
