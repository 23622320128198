import React from 'react'
import { Card } from 'react-bootstrap'
import DataTable from 'react-data-table-component'

const AttendeesTable = ({ data, columns,isLoading }) => {

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: "#0D3550B6",
                color: "#FFFFFF",
                fontSize: "16px",
                fontWeight: "bold",
                borderRadius: 'none'
            },
        },
        rows: {
            style: {
                minHeight: "50px", // Adjust row height
                fontSize: "14px",
                transition: "background-color 0.3s ease-in-out", // Smooth transition
                "&:hover": {
                    backgroundColor: "#65868F5B", // Light gray on hover
                    cursor: "pointer",
                }
            },
        },
        cells: {
            style: {
                padding: "10px",
            },
        },
    };
    return (
        < >
            <Card style={{ overflow: 'hidden' }}>
                <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    paginationPerPage={150}
                    paginationRowsPerPageOptions={[50,100,150,200]}
                    customStyles={customStyles}
                    progressPending={isLoading}
                />
            </Card>
        </>
    )
}

export default AttendeesTable
