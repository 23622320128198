import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import { Button } from 'react-bootstrap';
import './BecomeATenent.css'
import { Helmet } from 'react-helmet';

export const BecomeATenent = () => {
  return (
    <>
      <Helmet>
        <title>Become a Tenant | Find Your Ideal Rental Home | K-W Property</title>
        <meta name="description" content="Learn how to become a tenant, browse listings, and start your rental journey in Kitchener, Waterloo, or Cambridge today." />
        <meta name="keywords" content="Become a tenant Kitchener, Tenant application process Waterloo, Rental home process Cambridge, How to rent an apartment Ontario, Leasing a home Kitchener-Waterloo, Rental application guide, Find a rental home Ontario, Tenant screening process, Easy rental application, Rental requirements Ontario, First-time renter guide, Secure a rental home, Renting made simple, Apartment leasing process, Affordable rental options" />
        <link rel="canonical" href="https://www.kwproperty.com/BecomeATenent" />
      </Helmet>
      <KWNavbar></KWNavbar>
      <div className="container become-a-tenant-container mt-5 pt-5">
        {/* ********  Blank Page  ******** */}
        <h1 style={{ textAlign: "center" }} className="mt-5">
          Become A Tenant
        </h1>
        <div className="row">
          <div className="col text-center mt-4">
            <Button
              variant="outline-primary"
              className="me-2"
              onClick={() => {
                window.open('https://app.verifast.com/appv1/3000/KW-Property-Application/login')
              }}
            >
              Student Application
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => {
                window.open('https://app.verifast.com/appv1/3000/KW-Property-Application/login')
              }}
            >
              General Application
            </Button>
          </div>
        </div>
      </div>
      <KWFooter></KWFooter>
    </>
  );
}