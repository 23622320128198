import React from 'react'
import { Nav } from 'react-bootstrap'
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin, FaYoutube } from 'react-icons/fa'
import logo from '../../assets/logo_white.png'
import './footer.css'

const KWFooter = () => {
    return (
        <div className='footer'>
            <div className="footer-container">
                <div className="footer-row">
                    <div className='footer-left'>
                        {/* <Nav defaultActiveKey="/home" className="flex-column">
                            <Nav.Link eventKey="link-1" className='text-dark'>Link 1</Nav.Link>
                            <Nav.Link eventKey="link-1" className='text-dark'>Link 2</Nav.Link>
                            <Nav.Link eventKey="link-1" className='text-dark'>Link 3</Nav.Link>
                            <Nav.Link eventKey="link-1" className='text-dark'>Link 4</Nav.Link>
                            <Nav.Link eventKey="link-1" className='text-dark'>Link 4</Nav.Link>
                            <Nav.Link eventKey="link-1" className='text-dark'>Link 4</Nav.Link>
                        </Nav> */}
                        <img src={logo} alt="KW Property Management Logo - Trusted Rental & Property Management Services" className='mt-2 footer-img' />
                    </div>
                    <div className='footer-right'>
                        <div className="footer-right2">
                            <p className='dash text-primary me-2'>___</p>
                            <Nav defaultActiveKey="/home" className='pb-2'>
                                <a className='text-primary footer-icon' href="https://www.facebook.com/kwproperty/" target="_blank" rel="noopener noreferrer"><FaFacebook size={25} /></a>
                                <a className='text-primary footer-icon' href="https://twitter.com/i/flow/login?redirect_after_login=%2FKWPropertyM" target="_blank" rel="noopener noreferrer"><FaTwitter size={25} /></a>
                                <a className='text-primary footer-icon' href="https://www.instagram.com/kwproperty/" target="_blank" rel="noopener noreferrer"><FaInstagram size={25} /></a>
                                <a className='text-primary footer-icon' href="https://www.linkedin.com/company/k-w-property/" target="_blank" rel="noopener noreferrer"><FaLinkedin size={25} /></a>
                                <a className='text-primary footer-icon' href="https://www.youtube.com/@k-wpropertymanagement5470" target="_blank" rel="noopener noreferrer"><FaYoutube size={25} /></a>
                            </Nav>
                            <p className="text-dark lead mt-1">Copyright © 2018 K-W Property Management Corp.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KWFooter