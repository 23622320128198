
import '../Showing/Showing.css';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import 'moment-timezone'
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/AxiosInstance';
Moment.globalTimezone = 'America/Toronto'

const PropertyShowing = ({ id }) => {
    const [itemData, setItemData] = useState({});
    const navigate = useNavigate()
    const fetchData = () => {
        axiosInstance().get('/v1/showing/public/' + id).then((res) => {

            return res.data.groupedEvents;
        })
            .then((data) => {
                setItemData(data)
            })
            .catch(() => {
                console.log('error')
            })
    };
    useEffect(() => {
        fetchData()
    }, []);

    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    return (
        <>
            <div className="col-sm-12">
                {
                    Object.keys(itemData).length > 0 ?
                        Object.keys(itemData)?.map((key) => {
                            const cDay = moment.tz(key, "America/Toronto");
                            const title = `${daysOfWeek[cDay.day()]} , ${months[cDay.month()]} ${cDay.date()}  ${cDay.year()}`
                            // Set the desired time zone
                            const timeZone = 'America/Toronto';

                            // Convert the date to the desired time zone using moment-timezone
                            const formattedDate = moment.tz(key, timeZone);

                            // Check if the date is today or tomorrow
                            const isToday = moment().isSame(formattedDate, 'day');
                            const isTomorrow = moment().add(1, 'day').isSame(formattedDate, 'day');

                            // Set the formatted date based on the check
                            let displayDate;
                            if (isToday) {
                                displayDate = 'Today';
                            } else if (isTomorrow) {
                                displayDate = 'Tomorrow';
                            } else {
                                displayDate = formattedDate.format('MMM DD, YYYY');
                            }

                            return (
                                <>
                                    <div className="secound-content mt-2">
                                        <div className="content2-head">{displayDate}</div>
                                        <div className="content2-border"></div>
                                        <div className="content2-row">
                                            {
                                                itemData[key].map((item) => {
                                                    if (item.details !== undefined) {

                                                        const labl = new Date(item.start.dateTime);
                                                        labl.toLocaleString('en-US', { timeZone: item.start.timeZone })
                                                        let bookData = {
                                                            // summery: item.details?.summery,
                                                            title: title,
                                                            showingDate: title,
                                                            time: item.start.dateTime,
                                                            eventId: item.id,
                                                            extendedProperties: item.extendedProperties.shared
                                                        }



                                                        return (
                                                            <div className="row mt-2">
                                                                <div className="col-sm-12 col-md-2 time">
                                                                    <Moment date={item.start.dateTime} format="hh:mm a" />
                                                                </div>
                                                                <div className="col-sm-12 col-md-6">
                                                                    <p
                                                                        style={{ cursor: 'pointer', marginBottom: '0px', }}
                                                                        onClick={() => navigate(`/listing/${item.extendedProperties.shared.propertyId}`)} className='middle-link1'>{item.extendedProperties.shared.propertyTitle}</p>
                                                                    <small style={{ color: "#999998", marginTop: '-5px', textAlign: 'left' }} className='d-block p-0'>{item.extendedProperties.shared.unitTitle}</small>
                                                                    {/* <div className='content2-unit'>Unit C</div> */}
                                                                </div>
                                                                <div className="col-sm-12 col-md-4">
                                                                    {
                                                                        item.details.meetingStatus === "c" ? (
                                                                            <button className='book-Showing-c btn btn-danger'>CANCELLED</button>
                                                                        ) : (
                                                                            <button className='book-Showing btn btn-primary' onClick={() => {
                                                                                navigate(`/ConfirmProperty/${item.id}`, { state: { bookData } });
                                                                            }}>BOOK SHOWING</button>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    return <></>
                                                })
                                            }
                                        </div>
                                    </div>
                                </>
                            )
                        })
                        : (
                            <div className="secound-content mt-2">
                                <div className="text-black-50 fs-5">No Showings Available !</div>
                            </div>
                        )
                }
            </div>
            <br />
        </>
    )
}

export default PropertyShowing;