import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import './card.css'
import 'animate.css'

const TWCard = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className={`card-container mb-5 mx-2 p-4 ${isHovered ? 'animate__animated animate__pulse bg-primary' : ''} `}
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    >
      <div className="card-header d-flex w-100 align-items-center justify-content-start">
        <div className={`icon-bg p-2 mr-2 ${isHovered ? 'bg-light' : ''} `}>{props.icon}</div>
        <h3 className={`h3 text-start blue my-2 mx-2 ${isHovered ? 'text-light' : ''} `}>{props.heading}</h3>
      </div>
        <p style={{textAlign:'start'}} className={` ${isHovered ? 'text-light' : ''} ${isExpanded ? 'expanded' : 'card-paragraph'}`}>{props.paragraph}</p>
        <div className="card-footer w-100 d-flex align-items-center justify-content-end">
          <Button onClick={() => setIsExpanded(!isExpanded)} variant={`${isHovered ? 'outline-light' : 'outline-primary'}`}>Show {`${isExpanded?'Less':'More'}`} </Button>
        </div>
    </div>
  )
}

export default TWCard