import React from 'react';
import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import { Container, Row, Col } from 'react-bootstrap';
import property_investors from '../../assets/property_investors_checklist_v2.pdf';
import { Helmet } from 'react-helmet';

const Thankyou = () => {
  return (
    <>
      <Helmet>
        <title>Thank You for Downloading | KW Property eBook</title>
        <meta
          name="description"
          content="Thank you for downloading our free eBook! Explore more property management tips, rental solutions & expert insights from KW Property Management."
        />
        <meta
          name="keywords"
          content="Thank you for downloading eBook, Tenant and landlord resources, Rental management guide, Ontario property management insights, Kitchener rental solutions, Waterloo landlord support, Cambridge tenant assistance, Real estate tips and guides, Rental laws and best practices, KW Property Management services"
        />
        <link rel="canonical" href="https://www.kwproperty.com/ebook/thankyou" />
      </Helmet>
      <KWNavbar />
      <Container className='py-5 mt-5'>
        <Row className='justify-content-center'>
          <Col md={6}>
            <h1 className='mt-5 text-center'>Thank you!</h1>
            <h5 className='mt-4 text-center'>
              <a href={property_investors} download={true} rel='noreferrer'>
                Click here to download your free guide Now!
              </a>
            </h5>
            <h6 className='mt-5 text-center'>Phone: 519-954-8082</h6>
            <h6 className='mt-3 text-center'>
              Email: <a href="mailto:kwp@kwproperty.com">kwp@kwproperty.com</a>
            </h6>
          </Col>
        </Row>
      </Container>
      <div className="fixed-bottom">
        <KWFooter />
      </div>
    </>
  );
};

export default Thankyou;
