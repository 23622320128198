import React from 'react'
import './team.css'
import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import { Container } from 'react-bootstrap'
import { team } from '../../data/team'
import { Teamcard } from '../../components/teamcard/teamcard'
import './team.css'
import { Helmet } from 'react-helmet'
import { sameAsLinks } from "../../constants/schemaConstants"; // Import common sameAs array

export const Team = () => {
  return (
    <>
      <Helmet>
        <title>Meet Our Team | Expert Property Managers in Ontario</title>
        <meta
          name="description"
          content="Get to know the experts at K-W Property Management. Our experienced team ensures seamless property management services in Kitchener, Waterloo & Cambridge."
        />
        <meta
          name="keywords"
          content="Property management team Kitchener, Property management team Waterloo, Property management team Cambridge, Meet our property managers, Rental management experts Ontario, Professional property managers, Landlord support team, Tenant service specialists, Real estate management professionals, Experienced rental managers, Dedicated property consultants, Property maintenance specialists, Reliable leasing agents, Expert real estate services, Property administration team"
        />
        <link rel="canonical" href="https://www.kwproperty.com/team" />
        {/* JSON-LD structured data for SEO: Defines business details for a real estate agent. */}
        <script type="application/ld+json">
          {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "AboutPage",
              "name": "Meet Our Team | Expert Property Managers",
              "description": "Get to know the experts at K-W Property Management. Our experienced team ensures seamless property management services in Kitchener, Waterloo & Cambridge.",
              "url": "https://www.kwproperty.com/team",
              "mainEntity": {
                  "@type": "Organization",
                  "name": "K-W Property Management Corp.",
                  "url": "https://www.kwproperty.com",
                  "logo": "https://www.kwproperty.com/static/media/logo_blue.6195a1cf46880439d6fd.png",
                  "description": "K-W Property Management is a trusted property management company serving Kitchener, Waterloo & Cambridge.",
                  "contactPoint": {
                      "@type": "ContactPoint",
                      "telephone": "+1-519-954-8082",
                      "contactType": "customer service",
                      "availableLanguage": ["English"],
                      "email": "kwp@kwproperty.com"
                  },
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "541 Queen Street South",
                      "addressLocality": "Kitchener",
                      "addressRegion": "ON",
                      "postalCode": "N2G-1W9",
                      "addressCountry": "CA"
                  },
                  "employee": team.map(member => ({
                      "@type": "Person",
                      "name": member.name,
                      "jobTitle": member.designation,
                      "description": member.description,
                      "image": member.img
                  })),
                  "sameAs": sameAsLinks
              }
          })}
        </script>
      </Helmet>
      <KWNavbar/>
      <Container className='mt-5 pt-5'>
        <h1>Meet the <span className="blue">TEAM</span></h1>
        <h4 className='team-desc mb-5'>The strength of K-W Property Management Corp., is in our team and how we support each other to get the job done for our clients. We take great pride in our ability to work together to give our clients the most positive experience that working with a Property Management Company should be.</h4>
        {team.map((member)=> {
          return(
            <Teamcard key={member.id} name={member.name} designation={member.designation} description={member.description} img={member.img} note={member.note}></Teamcard>
          )
        })}
      </Container>
      <KWFooter/>
    </>
  )
}
