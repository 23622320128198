import React, { useEffect, useState, useCallback } from 'react';
import KWAdminNavbar from '../../components/navbar/AdminNav';
import AttendeesTable from '../../components/appoinment/Index';
import { Button, Card, Container } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import './singleShowing.css';
import axiosInstance from '../../utils/AxiosInstance';
import { verifyToken } from '../../utils/verifyToken';
import swal from 'sweetalert';

const SingleShowing = () => {
    const [searchParams] = useSearchParams();
    const eventId = searchParams.get('id');

    const [isLoading, setIsloading] = useState(true);
    const [itemData, setItemData] = useState();

    const fetchData = useCallback(() => {
        axiosInstance().get(`/v1/showing/appointments/${eventId}`)
            .then((res) => res.data.appointment)
            .then((data) => {
                setItemData(data);
                setIsloading(false);
            })
            .catch(() => {
                setIsloading(false);
            });
    }, [eventId]);

    useEffect(() => {
        fetchData();
        verifyToken();
    }, [fetchData]);
    const alertBeforeCancel = async (data) => {
        swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                const res = await axiosInstance().patch(`/v1/showing/appointment/${eventId}`, data);
                console.log(res)
                res.status === 200 && swal("Canceled!", "Appointment has been canceled.", "success").then(() => {
                    fetchData()
                })

            }
        });

    }
    // this function is responsiable for handling cancel appointment
    const handleCancelAppointment = (appoinment) => {
        const appointments = itemData;
        let appoin = {};
        for (let key in appointments) {
            key === appoinment.email && (appoin[key] = appointments[key]);
        };

        const agentData = JSON.parse(appoin[appoinment.email])
        appoin[appoinment.email] = JSON.stringify({ ...agentData, confirmed: false })
        const extendedProperties = {
            extendedProperties: {
                private: {
                    ...appoin
                }
            }
        }
        alertBeforeCancel(extendedProperties)
    }
    const parseAppointments = (appointments) => {
        try {
            const data = [];
            for (let key in appointments) {
                const restData = JSON.parse(appointments[key])
                data.push({
                    email: key,
                    ...restData
                })
            }
            return data;
        } catch (error) {
            if (error instanceof SyntaxError) {
                console.error("Invalid JSON syntax:", error.message);
                return []
            } else {
                console.error("Other error:", error);
                return []
            }
        }
    }
    const columns = [
        {
            name: "No",
            selector: (row, i) => i + 1,
            sortable: true,
            maxWidth: '20px'
        },
        {
            name: "Name",
            selector: row => row.name,
            sortable: true
        },
        {
            name: "Email",
            selector: row => row.email,
            sortable: true
        },
        {
            name: "Phone",
            selector: row => row.mobile,
            sortable: true
        },
        {
            name: "Action",
            selector: row => {
                return (
                    <Button
                        disabled={true}
                        onClick={() => handleCancelAppointment(row)}
                        size='sm' className='btn btn-danger'>Cancel</Button>
                )
            }

        }
    ];

    return (
        <div>
            <KWAdminNavbar />
            <br />
            <br />
            <Container className='container'>
                <div className='d-flex justify-content-end back-button'>
                    <Button
                        onClick={() => window.history.back()}
                        className='btn btn-secondary'>Back</Button>
                </div>
                <Card className='attendees-card'>
                    <Card.Header className='attendees-card-header'>
                        <h5 className='blue'>Confirmed Attendees for Showing at :</h5>
                        <span className='smallText'>{itemData?.propertyTitle}</span>
                    </Card.Header>
                </Card>
                <div className='table-container'>
                    <AttendeesTable
                        columns={columns}
                        isLoading={isLoading}
                        data={itemData ? (parseAppointments(itemData)) : []} />
                </div>
            </Container>
        </div>
    );
};

export default SingleShowing;
