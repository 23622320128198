import React from 'react'
import { KWBanner } from '../../components/banner/banner'
import KWIntro from '../../components/intro/intro'
import KWNumberSection from '../../components/numbersection/numbersection'
import KWServices from '../../components/services/services'
import KWPropertySection from '../../components/propertysection/propertysection'
import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import { Helmet } from 'react-helmet'
import { sameAsLinks } from "../../constants/schemaConstants"; // Import common sameAs array

export const Home = () => {
    return (
        <div>
            {/* Helmet for Meta Data */}
            <Helmet>
                <title>K-W Property Management | Rent & Buy Homes in Ontario</title>
                <meta
                    name="description"
                    content="Find expert property management, rental homes & rent-to-own options in Kitchener, Waterloo & Cambridge. Hassle-free leasing for tenants & landlords!"
                />
                <meta
                    name="keywords"
                    content="K-W Property Management, Property Management Kitchener, Property Management Waterloo, Property Management Cambridge, Rental Homes Ontario, Rent-to-Own Homes Kitchener, Residential Property Management, Tenant & Landlord Services, Leasing Solutions Ontario"
                />
                <link rel="canonical" href="https://www.kwproperty.com/" />
                {/* JSON-LD structured data for SEO: Defines business details for a real estate agent. */}
                <script type="application/ld+json">
                    {JSON.stringify(
                        {
                            "@context": "https://schema.org",
                            "@type": "RealEstateAgent",
                            "@id": "https://www.kwproperty.com/#realestateagent",
                            "name": "K-W Property Management Corp.",
                            "description": "K-W Property Management Corp. provides professional property management, rental homes, leasing, and real estate investment solutions in Kitchener, Waterloo, and Cambridge. We specialize in tenant placement, rent collection, maintenance, and legal compliance, ensuring hassle-free experiences for property owners and tenants.",
                            "image": "https://www.kwproperty.com/static/media/logo_blue.6195a1cf46880439d6fd.png",
                            "url": "https://www.kwproperty.com/",
                            "telephone": "+1-519-954-8082",
                            "email": "kwp@kwproperty.com",
                            "priceRange": "$1000+",
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "541 Queen Street South",
                                "addressLocality": "Kitchener",
                                "addressRegion": "ON",
                                "postalCode": "N2G-1W9",
                                "addressCountry": "CA"
                            },
                            "geo": {
                                "@type": "GeoCoordinates",
                                "latitude": 43.4437114,
                                "longitude": -80.4950913
                            },
                            "serviceArea": {
                                "@type": "AdministrativeArea",
                                "name": "Ayr, Barrie, Bracebridge, Brantford, Breslau, Burlington, Cambridge, Chatham, Cornwall, Elmira, Guelph, Hagersville, Hamilton, Hamilton/Binbrook, Kingston, Kitchener, Listowel, London, Milton, Mississauga, New Hamburg, Niagara Falls, North Bay, North York, Oakville, Orillia, Oshawa, Ottawa, Paris, Peterborough, Petersburg, Port Stanley, Sault Ste. Marie, St. Catharines, Stratford, Sudbury, Thunder Bay, Timmins, Toronto, Waterloo, Welland, Wellesley, Whitby, Windsor, Woodstock"
                            },
                            "makesOffer": [
                                {
                                    "@type": "Offer",
                                    "name": "Property Management",
                                    "description": "Comprehensive property management services tailored for landlords and real estate investors. We handle tenant screening, lease management, maintenance coordination, legal compliance, and financial reporting to ensure optimal property performance in Kitchener, Waterloo, and Cambridge."
                                },
                                {
                                    "@type": "Offer",
                                    "name": "Rental Homes",
                                    "description": "Browse high-quality rental homes, including apartments, townhouses, and single-family houses in Kitchener, Waterloo, and Cambridge. We connect tenants with their ideal homes and assist landlords in filling vacancies quickly and efficiently."
                                },
                                {
                                    "@type": "Offer",
                                    "name": "Rent-to-Own Options",
                                    "description": "Explore flexible rent-to-own programs designed for aspiring homeowners in Kitchener, Waterloo, and Cambridge. This option helps tenants transition from renting to ownership by building equity while living in their desired home."
                                },
                                {
                                    "@type": "Offer",
                                    "name": "Leasing for Tenants and Landlords",
                                    "description": "Expert leasing services ensuring a smooth and legally compliant rental experience for both landlords and tenants. From lease agreements to tenant placement and ongoing management, we make renting effortless."
                                },
                                {
                                    "@type": "Offer",
                                    "name": "Real Estate Investment Services",
                                    "description": "We assist property investors in acquiring, managing, and optimizing rental properties for long-term financial growth. Our expert team provides market insights, property management, and ROI-focused investment strategies."
                                },
                                {
                                    "@type": "Offer",
                                    "name": "Short-Term Rentals & Vacation Homes",
                                    "description": "Maximize your earnings from short-term rentals with our expert property management services. We handle guest bookings, maintenance, and marketing to ensure high occupancy and positive reviews."
                                }
                            ],
                            "openingHours": "Mo-Fr 09:00-17:00",
                            "contactPoint": {
                                "@type": "ContactPoint",
                                "telephone": "+1-519-954-8082",
                                "contactType": "customer service",
                                "availableLanguage": ["English"]
                            },
                            "sameAs": sameAsLinks
                        }
                    )}
                </script>
            </Helmet>
            <KWNavbar />
            <KWBanner />
            <KWIntro />
            <KWNumberSection />
            <KWServices />
            <KWPropertySection />
            <KWFooter />
        </div>
    )
}
