import React from 'react'
import { Button } from 'react-bootstrap'
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer'

export const NotFound = () => {
  return (
    <>
      <KWNavbar />
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div>
          <h2 className="text-center fw-medium">Not Found</h2>
          <p className='text-center fw-medium'>This page doesn't exist.</p>
          <div className="mt-3 text-center">
            <Link to='/'>
              <Button><span><FaArrowLeftLong /></span> Back To Home</Button>
            </Link>
          </div>
        </div>
      </div>
      <KWFooter />
    </>
  )
}
