import React, { useEffect, useState } from 'react';
import KWNavbar from "../../components/navbar/navbar";
import KWFooter from "../../components/footer/footer";
import { Button, Col, Collapse, Container, Image, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import Carousel from 'react-bootstrap/Carousel';


import samplePic from '../../assets/no-image.png';
import camera from '../../assets/icons8-compact-camera-50.png';
import { FaBath, FaBed, FaCheck } from "react-icons/fa";
import { FcCollapse } from "react-icons/fc";
import { LineWave } from 'react-loader-spinner';
import { LiaVectorSquareSolid } from "react-icons/lia";
import './PropertyView.css';
import { Helmet } from 'react-helmet';
import { sameAsLinks } from "../../constants/schemaConstants"; // Import common sameAs array
import PropertyShowing from './PropertyShowing';

import { MapContainer, TileLayer, Marker, Popup, LayersControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import markerShadowPng from "leaflet/dist/images/marker-shadow.png";

export default function PropertyView() {

    const [modalShow, setModalShow] = useState(false);
    const [listing, setListing] = useState();
    const location = useLocation();
    const id = location.pathname.split('/')[2];
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [unitDescription, setUnitDescription] = useState();
    const [open, setOpen] = useState(false);
    const [googleMapsUrl, setgoogleMapsUrl] = useState('')

    useEffect(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/v1/listing/kwpweb/unit/${id}`)
            .then(async (resp) => (resp.ok ? await resp.json() : Promise.reject()))
            .then((data) => {
                setLoading(false);
                setListing(data);
                getUnitDescription(data);
            })
            .catch(() => setLoading(false));
    }, [id]);

    const handleApplyOnline = () => {
        //window.open(`https://app.verifast.com/appv1/3000/KW-Property-Application/login`);
        window.open(`https://manage.mipropertyportal.com/App/RentalApplication/Index?propertyId=${listing.Unit.propertyId.replace('P', '')}&unitTypeId=${listing.Unit.Id.replace('T', '')}`)
    };

    const handleNavigate = () => {
        navigate(`/ScheduleShowing?uid=${listing.Unit.Id}`);
    };

    const renderDescription = (description) => {
        const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
        const descriptionWithLinks = description.replace(emailRegex, (match) => `<a href="mailto:${match}">${match}</a>`);
        const formattedDescription = descriptionWithLinks.replace(/\n/g, '<br/>');
        return <h5 dangerouslySetInnerHTML={{ __html: formattedDescription }} className="text-align-adjust" />;
    };

    const formatNumber = (number) => number.toLocaleString('en-US');
    function getUnitDescription(listing) {
        if (listing?.Unit?.unitDescription) {
            try {
                const description = JSON.parse(listing.Unit.unitDescription);
                setUnitDescription(description);
            } catch (error) {
                setUnitDescription([]);
            }
        }
    }

    const customIcon = new L.Icon({
        iconUrl: markerIconPng,
        shadowUrl: markerShadowPng,
        iconSize: [25, 41], // Default size
        iconAnchor: [12, 41], // Center bottom anchor
        popupAnchor: [1, -34],
    });
    useEffect(() => {
        const MapsUrl = `https://www.google.com/maps?q=${listing?.Unit.latitude},${listing?.Unit.longitude}`;
        setgoogleMapsUrl(MapsUrl)
    }, [listing])
    return (
        <>
            <div>
                <KWNavbar />
                <Container className="p-5">
                    {listing ? (
                        <>
                            <Helmet>
                                <title>{`${listing?.Property?.PropertyUnitTypeTitle} | ${listing?.Property.Name}`}</title>
                                <meta
                                    name="description"
                                    content={`Find ${listing?.Unit.unitType} for rent at ${listing?.Property.Name}. This ${listing?.Unit.UnitBedrooms !== "PARKING STALL" ? `${listing?.Unit.UnitBedrooms}-bedroom` : ""
                                        } unit with ${listing?.Unit.UnitBathrooms} bathroom(s) offers ${listing?.Unit.Features?.join(", ")}. Rent: $${listing?.Rent}/month. Located in ${listing?.Unit.Address.AddressLine1}.`}
                                />
                                <meta
                                    name="keywords"
                                    content={`
                                        ${listing?.Unit.unitType} for rent, 
                                        ${listing?.Property.Name}, 
                                        ${listing?.Property.PropertyUnitTypeTitle}, 
                                        ${listing?.Unit.UnitBedrooms !== "PARKING STALL" ? `${listing?.Unit.UnitBedrooms}-bedroom apartment` : "Parking space"}, 
                                        ${listing?.Unit.UnitBathrooms > 0 ? `${listing?.Unit.UnitBathrooms} bathroom` : ""}, 
                                        ${listing?.Unit.Features?.join(", ")}, 
                                        ${listing?.Unit.Address.City.trim()} rental, 
                                        ${listing?.Unit.Address.PostalCode.trim()}, 
                                        ${listing?.Rent ? `Affordable rent ${listing?.Rent}/month` : ""}, 
                                        best rental properties in ${listing?.Unit.Address.City.trim()}, 
                                        rental near ${listing?.Unit.Address.City.trim()}, 
                                        house for rent in ${listing?.Unit.Address.City.trim()}, 
                                        apartment for rent ${listing?.Unit.Address.PostalCode.trim()}, 
                                        ${listing?.Unit.unitType} leasing in ${listing?.Unit.Address.City.trim()}, 
                                        pet-friendly rentals in ${listing?.Unit.Address.City.trim()}
                                    `.replace(/\s+/g, " ").trim()
                                    }
                                />

                                <link rel="canonical" href={`https://www.kwproperty.com/listing/${listing?.Unit.Id}`} />
                                {/* JSON-LD structured data for SEO: Defines business details for a real estate agent. */}
                                <script type="application/ld+json">
                                    {JSON.stringify({
                                        "@context": "https://schema.org",
                                        "@type": "RealEstateListing",
                                        "name": listing?.Property?.PropertyUnitTypeTitle || "Parking Space for Rent",
                                        "url": `https://www.kwproperty.com/listing/${listing?.Unit?.Id}`,
                                        "description": `Find ${listing?.Unit?.unitType} for rent at ${listing?.Property?.Name}. This ${listing?.Unit?.UnitBedrooms !== "PARKING STALL" ? `${listing?.Unit?.UnitBedrooms}-bedroom` : ""} unit with ${listing?.Unit?.UnitBathrooms} bathroom(s) offers ${listing?.Unit?.Features?.join(", ")}. Rent: $${listing?.Rent}/month. Located in ${listing?.Unit?.Address?.AddressLine1}.`,
                                        "image": listing?.Unit?.Files?.length > 0 ? listing?.Unit.Files.map(file => file.Url) : [],
                                        "mainEntity": {
                                            "@type": "Place",
                                            "name": listing?.Property?.Name || "Rental Property",
                                            "address": {
                                                "@type": "PostalAddress",
                                                "streetAddress": listing?.Unit?.Address?.AddressLine1?.trim() || "",
                                                "addressLocality": listing?.Unit?.Address?.City?.trim() || "Kitchener",
                                                "postalCode": listing?.Unit?.Address?.PostalCode?.trim() || "",
                                                "addressCountry": "CA"
                                            }
                                        },
                                        "offers": {
                                            "@type": "Offer",
                                            "price": listing?.Rent ? listing.Rent.toString() : "150",
                                            "priceCurrency": "CAD",
                                            "availability": "https://schema.org/InStock",
                                            "validFrom": "2024-03-01T00:00:00Z",
                                            "url": `https://www.kwproperty.com/listing/${listing?.Unit?.Id}`
                                        },
                                        "provider": {
                                            "@type": "RealEstateAgent",
                                            "name": "K-W Property Management",
                                            "url": "https://www.kwproperty.com/"
                                        },
                                        "sameAs": sameAsLinks
                                    })}
                                </script>
                            </Helmet>
                            <Row className="pt-5 mt-5">
                                <Col sm={12} lg={6}>
                                    <h1 className="text-align-adjust fs-2 my-3">{listing?.Property.Name}</h1>
                                    <h6 className="text-align-adjust fs-6 fw-normal lh-base">{listing?.Property.PropertyUnitTypeTitle}</h6>
                                    <h2 className="fw-normal text-left fs-5 address">
                                        <b className='text-capitalize'>
                                            {listing?.Unit?.Address?.AddressLine1 &&
                                                `${listing.Unit.Address.AddressLine1.split(',')
                                                    .filter((v, i, a) => (i !== (a.length - 1) && i !== (a.length - 2)))
                                                    .join(' ,').toLowerCase()}.`}
                                        </b>
                                    </h2>
                                    <h2 className='text-capitalize fs-5'>
                                        <b>
                                            {listing?.Unit?.Address?.AddressLine1 &&
                                                `${listing.Unit.Address.AddressLine1.split(',')
                                                    .filter((v, i, a) => (i === (a.length - 2)))
                                                    .join(' ,').toLowerCase()},`}
                                            {`${listing?.Unit?.Address?.PostalCode}`}
                                        </b>
                                    </h2>
                                    <div >
                                        <h5 className="text-align-adjust">
                                            {listing?.Unit?.Description && renderDescription(listing.Unit.Description)}
                                        </h5>


                                        <ul className="text-align-adjust">
                                            {unitDescription?.map((item, index) => (

                                                <li className='fw-semibold text-align-adjust' key={index}>{item}</li>

                                            ))}
                                        </ul>

                                        <h5>
                                            <h5>

                                                *Non-Smoking Unit*
                                            </h5>
                                            <h5>
                                                *Tenant Insurance is Mandatory and Must be Provided on Move in Day*
                                            </h5>
                                            <h5>
                                                *Any Unit Listed by KWP That States All Inclusive Will Have a Cap Included*
                                            </h5>
                                            <hr></hr>

                                        </h5>
                                        <h5 className="text-align-adjust">
                                            To book an appointment check our online calendar for upcoming
                                            showings <a href={`${process.env.REACT_APP_BASE_URL}/Showing`}>here</a>. If you do not find a suitable showing time, please email us your availability and we will book you a time to see the unit.
                                        </h5>
                                    </div>
                                    <h3 className="blue mt-5">${formatNumber(listing?.Rent)}/month</h3>
                                    <Button onClick={handleApplyOnline} className="btn btn-primary d-block mt-4 button-c">Apply Online Today</Button>
                                    <Button onClick={handleNavigate} className="btn btn-primary d-block mt-4 button-c">Book Appointment</Button>
                                    <br />
                                    <div className='content-row row'>
                                        <div
                                            onClick={() => setOpen(!open)}
                                            aria-controls="collapse-text"
                                            aria-expanded={open}
                                            className='col-12 collapTrigger overflow-hidden border rounded-2 mx-auto py-2 align-items-center d-flex justify-content-between'>
                                            <h4 className=''>Scheduled Showings</h4>
                                            <FcCollapse className={open ? 'CollapIcon0' : 'CollapIcon180'} />
                                        </div>
                                        <div className='col-12'>
                                            <Collapse in={open}>
                                                <div id="collapse-text">
                                                    <PropertyShowing id={id} />
                                                </div>
                                            </Collapse>
                                        </div>
                                    </div>
                                </Col>

                                <Col sm={12} lg={6} className="ps-lg-5 mt-5 mt-lg-0 position-relative">
                                    <Image
                                        src={listing?.Unit.defaultImg || samplePic}
                                        alt={listing?.Property?.PropertyUnitTypeTitle
                                            ? `${listing.Property.PropertyUnitTypeTitle} - ${listing.Unit.Address.City}, ${listing.Unit.Address.PostalCode} | ${listing.Unit.UnitBedrooms} Bedroom Apartment for Rent`
                                            : "Apartment for Rent - Spacious Rental Unit"}
                                        className="image-container mb-4 mb-lg-0"
                                        fluid
                                    />
                                    <button onClick={() => setModalShow(true)} className="seePhotoBtn">
                                        <img className="p-1" src={camera}
                                            alt={`See all photos of ${listing?.Property?.PropertyUnitTypeTitle} at ${listing?.Unit?.Address?.City.trim()}, ${listing?.Unit?.Address?.PostalCode.trim()}`}
                                            width={30} />

                                        See all photos
                                    </button>
                                    <div className='w-100 Pdetails py-4 d-flex justify-content-around'>
                                        <div>
                                            <FaBed title='Bedrooms' size={20} className="icon-primary" />
                                            <span>{" " + listing?.Unit?.UnitBedrooms || 'N/A'}</span>
                                        </div>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <FaBath title='Bathrooms' size={20} className="icon-primary" />
                                            <span>{" " + listing?.Unit?.UnitBathrooms || 'N/A'}</span>
                                        </div>
                                        <div>
                                            <LiaVectorSquareSolid title='Sq Ft' size={20} className="icon-primary" />
                                            <span>{" " + (listing?.Unit?.UnitSize !== 0 ? listing?.Unit?.UnitSize : 'N/A')}</span>
                                        </div>
                                    </div>
                                    <div className='map-container'>
                                        <div className='my-4'>

                                        </div>
                                        <MapContainer center={[listing?.Unit.latitude, listing?.Unit.longitude]} zoom={15} style={{ height: "500px", width: "100%" }}>
                                            <LayersControl position="topright">
                                                {/* Street Map Layer (Default) */}
                                                <LayersControl.BaseLayer checked name="Street Map">
                                                    <TileLayer
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                        attribution='&copy; OpenStreetMap contributors'
                                                    />
                                                </LayersControl.BaseLayer>

                                                {/* Satellite View Layer */}
                                                <LayersControl.BaseLayer name="Satellite View">
                                                    <TileLayer
                                                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                                        attribution='&copy; <a href="https://www.esri.com/">Esri</a> &mdash; Source: Esri, Maxar, Earthstar Geographics'
                                                    />
                                                </LayersControl.BaseLayer>
                                            </LayersControl>
                                            <Marker position={[listing?.Unit.latitude, listing?.Unit.longitude]}
                                                icon={customIcon}
                                            >
                                                <Popup>
                                                    <b>Your Location</b> <br />
                                                    <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
                                                        Open in Google Maps
                                                    </a>
                                                </Popup>
                                            </Marker>
                                        </MapContainer>
                                    </div>
                                </Col>
                            </Row>

                            {listing?.Unit?.Features?.length > 0 && (
                                <>
                                    <br /><br />
                                    <div className="content-row row">
                                        <div className="col-12">
                                            <div className="panel features-panel">
                                                <div className="panel-title">Features</div>
                                                <ul className="feature-list fa-ul no-bullets">
                                                    {listing?.Unit?.Features.map((feature, index) => (
                                                        <li key={index} className='Features-li'>
                                                            <FaCheck className='text-success mx-2' />
                                                            {feature}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {listing?.Unit?.Files?.length > 0 && (
                                <>
                                    <br /><br />
                                    <div className="content-row row">
                                        <div className="col-12">
                                            <div className="panel features-panel">
                                                <div className="panel-title">More Photos</div>
                                                <div className='MorePhotos'>
                                                    {listing?.Unit?.Files.map((file, index) => (
                                                        <div key={index}>
                                                            <img
                                                                onClick={() => setModalShow(true)}
                                                                src={file.Url}
                                                                alt={`More photos of ${listing?.Property?.PropertyUnitTypeTitle} at ${listing?.Unit?.Address?.City.trim()}, ${listing?.Unit?.Address?.PostalCode.trim()}`}
                                                                className='MorePhotos-Img'
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <div className="d-flex justify-content-center">
                            {loading && <LineWave height="200px" width="300px" color="#0586f0" ariaLabel="line-wave" visible={true} />}
                        </div>
                    )}
                </Container>

                <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <div className="center h-50">
                            <Carousel>
                                {listing?.Unit.Files?.map((img, index) => (
                                    <Carousel.Item key={index}>
                                        <Image src={img.Url} alt={`Image ${index + 1} of ${listing?.Property?.Name} - ${listing?.Property?.PropertyUnitTypeTitle || "Property"} in ${listing?.Unit?.Address?.AddressLine1 || "Unknown Location"}`} className="image-containers mb-4 mb-lg-0" fluid />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </Modal.Body>
                </Modal>
                <KWFooter />
            </div>
        </>
    );
}